import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
} from "@angular/core";
import { Table } from "../../s25-table/Table";
import { GenericTableButtonComponent } from "../../s25-table/generics/generic.table.button.component";
import { S25TableComponent } from "../../s25-table/s25.table.component";
import { Bind } from "../../../decorators/bind.decorator";
import { TypeManagerDecorator } from "../../../main/type.map.service";
import { PricingService } from "../../../services/pricing.service";
import { Billing } from "../../../pojo/Pricing";
import { S25Datefilter } from "../../s25-dateformat/s25.datefilter.service";
import { UserprefService } from "../../../services/userpref.service";

@TypeManagerDecorator("s25-ng-document-invoice-list")
@Component({
    selector: "s25-ng-document-invoice-list",
    template: ` <s25-ng-table [dataSource]="tableConfig" [columnSortable]="true"></s25-ng-table> `,
    styles: [],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25DocumentInvoiceListComponent implements OnInit, OnChanges {
    @Input({ required: true }) eventId: number;
    @Input() onSelected: (value: number) => void;

    @Output() selected = new EventEmitter<number>();

    @ViewChild(S25TableComponent) tableComponent: S25TableComponent;

    tableConfig: Table.DataSource;
    dateFormat: string;

    constructor(private changeDetector: ChangeDetectorRef) {}

    ngOnChanges(changes: SimpleChanges) {
        if (changes.eventId) this.tableComponent?.refresh();
    }

    ngOnInit() {
        this.initTableConfig();
        this.changeDetector.detectChanges();
    }

    initTableConfig() {
        this.tableConfig = {
            type: "unpaginated",
            dataSource: this.getRows,
            columns: [
                { id: "invoice", header: "Invoice" },
                { id: "total", header: "Grand Total" },
                { id: "date", header: "Date" },
                GenericTableButtonComponent.Column("Select", this.selectInvoice),
            ],
        };
    }

    @Bind
    async getRows(): Promise<Table.DataSourceResponse> {
        const [invoices, dateFormat] = await Promise.all([
            PricingService.getPricingSetsForEvents([this.eventId]),
            UserprefService.getS25Dateformat(),
        ]);
        this.dateFormat = dateFormat;

        return {
            rows: invoices.data?.items?.map(this.mapToRow),
        };
    }

    @Bind
    mapToRow(invoice: Billing) {
        return {
            id: invoice.id,
            name: invoice.billDefn.billName,
            cells: {
                invoice: { text: invoice.billDefn.billName },
                total: { text: PricingService.formatCurrency(invoice.billing.totals.grandTotal) },
                date: {
                    text: S25Datefilter.transform(invoice.billDefn.billDate, this.dateFormat),
                    sortValue: invoice.billDefn.billDate,
                },
            },
        };
    }

    @Bind
    async selectInvoice(row: Table.Row) {
        this.onSelected?.(row.id as number);
        this.selected.emit(row.id as number);
    }
}
