import {
    ChangeDetectionStrategy,
    Component,
    OnInit,
    ViewChild,
    ChangeDetectorRef,
    ViewEncapsulation,
    Input,
} from "@angular/core";
import { Table } from "../../s25-table/Table";
import { Bind } from "../../../decorators/bind.decorator";
import { GenericTableButtonComponent } from "../../s25-table/generics/generic.table.button.component";
import { DocumentListItem, DocumentService } from "../../../services/document.service";
import { ModalService } from "../../modal/modal.service";
import { S25TableComponent } from "../../s25-table/s25.table.component";
import { S25Util } from "../../../util/s25-util";
import { TypeManagerDecorator } from "../../../main/type.map.service";
import { CacheRepository } from "../../../decorators/cache.decorator";
import { Doc } from "./s25.document.const";
import { FlsService } from "../../../services/fls.service";
import { Fls, hasEditFls, hasFullFls } from "../../../pojo/Fls";
import { ReportService } from "../../../services/report.service";
import { S25Help } from "../../s25-help/s25.help.service";

@TypeManagerDecorator("s25-ng-document-list")
@Component({
    selector: "s25-ng-document-list",
    template: `
        @if (isInit) {
            <div class="top">
                @if (!isTemplate) {
                    <div class="header">
                        <button class="aw-button aw-button--primary" (click)="onCreateClick()" [disabled]="!canCreate">
                            Create Document
                        </button>
                    </div>
                    @if (!canCreate) {
                        <div class="small c-margin-bottom--single ">
                            Note: You will need the permissions described
                            <a href="{{ documentHelpLink }}" s25Help [helpTopic]="'document'" target="_blank">here</a>
                            in order to create a document.
                        </div>
                    }
                }
                <s25-ng-table
                    [dataSource]="tableConfig"
                    [hasRefresh]="true"
                    [hasFilter]="true"
                    [columnSortable]="true"
                ></s25-ng-table>
            </div>
        }
    `,
    styles: `
        .top {
            max-width: 1200px;
        }

        .header {
            display: flex;
            justify-content: space-between;
        }

        .header > button {
            margin-bottom: 1em;
        }
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.Emulated,
})
export class S25DocumentListComponent implements OnInit {
    @Input() isTemplate: boolean = false;
    @ViewChild(S25TableComponent) tableComponent: S25TableComponent;

    isInit = false;
    tableConfig: Table.DataSource;
    documents = new Map<number, DocumentListItem>();
    fls: Fls;
    defaultFileNames: Record<Doc.ScopeReportId, string>;
    documentHelpLink = S25Help.getHelpUrl("document");
    canCreate: boolean = false;
    canDelete: boolean = false;

    constructor(private cd: ChangeDetectorRef) {}

    async ngOnInit() {
        this.fls = await FlsService.getFls();
        // get document scope  list, if none,  create doucment button disable
        this.defaultFileNames = await ReportService.getDefaultDocumentFileNames();
        this.canCreate = hasFullFls(this.fls.REP_LIST) && !!Object.keys(this.defaultFileNames).length;
        this.canDelete = hasFullFls(this.fls.REP_LIST) && !this.isTemplate;
        this.initTableConfig();

        this.isInit = true;
        this.cd.detectChanges();
    }

    initTableConfig() {
        const columns: Table.Column[] = [
            { id: "name", header: "Name" },
            { id: "scope", header: "Scope" },
            this.canCreate && GenericTableButtonComponent.Column("Copy", this.onCopyClick, "outline"),
            GenericTableButtonComponent.Column("View", this.onEditClick, "outline"),
            this.canDelete && GenericTableButtonComponent.Column("Delete", this.onDeleteClick, "danger--outline"),
        ].filter((col) => col);

        this.tableConfig = { type: "unpaginated", dataSource: this.getRows, columns };
    }

    @Bind
    async getRows(query: Table.UnpaginatedQuery): Promise<Table.DataSourceResponse> {
        if (query.forceRefresh) this.invalidateCache();

        const documents = await this.getDocumentList();
        for (let doc of documents) {
            this.documents.set(doc.itemId, doc);
        }

        return {
            rows: documents.map(this.mapToRow),
        };
    }

    invalidateCache() {
        if (this.isTemplate) {
            CacheRepository.invalidateByService("DocumentService", "getDocumentTemplateList");
            CacheRepository.invalidateByService("DocumentService", "getDocumentTemplate");
        } else {
            CacheRepository.invalidateByService("DocumentService", "getDocumentList");
        }
    }

    getDocumentList() {
        if (this.isTemplate) {
            return DocumentService.getDocumentTemplateList();
        }
        return DocumentService.getDocumentList();
    }

    @Bind
    mapToRow(doc: DocumentListItem): Table.Row {
        return {
            id: doc.itemId,
            name: doc.itemName,
            cells: {
                name: { text: doc.itemName },
                scope: { text: Doc.scope[doc.type]?.label || doc.type },
                header: { text: "header" },
                copy: {
                    inputs: {
                        label: "Copy",
                        type: "outline",
                        disabled: !this.isTemplate && !hasFullFls(doc.accessLevel),
                        disabledReason: "You lack permission to copy this document",
                    },
                },
                view: {
                    inputs: {
                        label: hasEditFls(doc.accessLevel) ? "Edit" : "View",
                        type: "outline",
                    },
                },
                delete: {
                    inputs: {
                        label: "Delete",
                        type: "danger--outline",
                        disabled: !hasFullFls(doc.accessLevel),
                        disabledReason: "You lack permission to delete this document",
                    },
                },
            },
        };
    }

    onCreateClick() {
        ModalService.modal("edit-document", {
            mode: "create",
            title: `Create Document`,
            repList: this.fls.REP_LIST,
            onSave: this.tableComponent.refresh,
        });
    }

    @Bind
    onEditClick(row: Table.Row) {
        const document = this.documents.get(row.id as number);
        const mode = hasEditFls(document.accessLevel) ? "edit" : "view";
        ModalService.modal("edit-document", {
            id: row.id,
            mode,
            repList: this.fls.REP_LIST,
            title: `${S25Util.firstCharToUpper(mode)} Document: ${row.name}`,
            onSave: this.tableComponent.refresh,
            onCopy: () => this.onCopyClick(row),
            isTemplate: this.isTemplate,
        });
    }

    @Bind
    onCopyClick(row: Table.Row) {
        ModalService.modal("edit-document", {
            id: row.id,
            mode: "copy",
            repList: this.fls.REP_LIST,
            title: `Copy Document: ${row.name}`,
            onSave: this.tableComponent.refresh,
            isTemplate: this.isTemplate,
        });
    }

    @Bind
    async onDeleteClick(row: Table.Row) {
        let dialogData = ModalService.dialogType(
            "Yes No",
            {
                message: "Are you sure you want to delete this document?",
                title: "Confirm Deletion",
            },
            "No",
        );
        await ModalService.modal("dialog", dialogData);
        if (dialogData.answer !== 1) return; // User answered no

        const [_, error] = await S25Util.Maybe(DocumentService.deleteDocument(row.id as number));
        if (error) return S25Util.showError(error);
        return this.tableComponent.refresh();
    }
}
