import { NgModule } from "@angular/core";
import { S25DocumentListComponent } from "./s25.document.list.component";
import { CommonModule } from "@angular/common";
import { S25TableModule } from "../../s25-table/s25.table.module";
import { S25EditDocumentComponent } from "./s25.edit.document.component";
import { FormsModule } from "@angular/forms";
import { S25LoadingInlineModule } from "../../s25-loading/s25.loading.inline.module";
import { S25RichTextEditorModule } from "../../s25-rich-text-editor/s25.rich.text.editor.module";
import { S25SimpleCollapseModule } from "../../s25-simple-collapse/s25.simple.collapse.module";
import { BpeModule } from "../../bpe/bpe.module";
import { S25DocumentReservationListComponent } from "./s25.document.reservation.list.component";
import { S25ModalModule } from "../../s25-modal/s25.modal.module";
import { S25DatepickerModule } from "../../s25-datepicker/s25.datepicker.module";
import { S25DropdownPaginatedModule } from "../../s25-dropdown/s25.dropdown.paginated.module";
import { S25PipesModule } from "../../../pipes/s25.pipes.module";
import { S25HelpDirective } from "../../s25-help/s25.help.directive";
import { S25DocumentInvoiceListComponent } from "./s25.document.invoice.list.component";
@NgModule({
    imports: [
        CommonModule,
        S25TableModule,
        FormsModule,
        S25LoadingInlineModule,
        S25RichTextEditorModule,
        S25SimpleCollapseModule,
        BpeModule,
        S25ModalModule,
        S25DatepickerModule,
        S25DropdownPaginatedModule,
        S25PipesModule,
        S25HelpDirective,
    ],
    declarations: [
        S25DocumentListComponent,
        S25EditDocumentComponent,
        S25DocumentReservationListComponent,
        S25DocumentInvoiceListComponent,
    ],
    exports: [
        S25DocumentListComponent,
        S25EditDocumentComponent,
        S25DocumentReservationListComponent,
        S25DocumentInvoiceListComponent,
    ],
    providers: [
        S25DocumentListComponent,
        S25EditDocumentComponent,
        S25DocumentReservationListComponent,
        S25DocumentInvoiceListComponent,
    ],
})
export class S25DocumentManagementModule {}
