import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
} from "@angular/core";
import { Table } from "../../s25-table/Table";
import { GenericTableButtonComponent } from "../../s25-table/generics/generic.table.button.component";
import { EventReservation, ReservationService } from "../../../services/reservation.service";
import { S25TableComponent } from "../../s25-table/s25.table.component";
import { Bind } from "../../../decorators/bind.decorator";
import { TypeManagerDecorator } from "../../../main/type.map.service";

@TypeManagerDecorator("s25-ng-document-reservation-list")
@Component({
    selector: "s25-ng-document-reservation-list",
    template: ` <s25-ng-table [dataSource]="tableConfig" [columnSortable]="true"></s25-ng-table> `,
    styles: [],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25DocumentReservationListComponent implements OnInit, OnChanges {
    @Input({ required: true }) eventId: number;

    @Input() onSelect: (reservationId: number) => void;
    @Output() selected = new EventEmitter<number>();

    @ViewChild(S25TableComponent) tableComponent: S25TableComponent;

    tableConfig: Table.DataSource;

    constructor(private changeDetector: ChangeDetectorRef) {}

    ngOnChanges(changes: SimpleChanges) {
        if (changes.eventId) this.tableComponent?.refresh();
    }

    ngOnInit() {
        this.initTableConfig();
        this.changeDetector.detectChanges();
    }

    initTableConfig() {
        this.tableConfig = {
            type: "unpaginated",
            dataSource: this.getRows,
            columns: [
                {
                    id: "date",
                    header: "Date",
                },
                {
                    id: "state",
                    header: "State",
                    width: 100,
                },
                {
                    id: "locations",
                    header: "Locations",
                },
                {
                    id: "resources",
                    header: "Resources",
                },
                GenericTableButtonComponent.Column("Preview", this.previewOccurrence),
            ],
        };
    }

    @Bind
    async getRows(): Promise<Table.DataSourceResponse> {
        const reservations = await ReservationService.getEventReservations(this.eventId);

        return {
            rows: reservations.map(this.mapToRow),
        };
    }

    mapToRow(reservation: EventReservation) {
        return {
            id: reservation.reservation_id,
            name: reservation.dateTime,
            cells: {
                date: { text: reservation.dateTime },
                state: { text: reservation.stateName },
                locations: { text: reservation.locations },
                resources: { text: reservation.resources },
            },
        };
    }

    @Bind
    async previewOccurrence(row: Table.Row) {
        this.selected.emit(row.id as number);
        this.onSelect?.(row.id as number);
    }
}
