import { NgModule } from "@angular/core";
import { S25BlackoutManagementListComponent } from "./s25.blackout.management.list.component";
import { S25TableModule } from "../../s25-table/s25.table.module";
import { S25ModalModule } from "../../s25-modal/s25.modal.module";
import { CommonModule } from "@angular/common";
import { S25DatepickerModule } from "../../s25-datepicker/s25.datepicker.module";
import { S25EditableModule } from "../../s25-editable/s25.editable.module";
import { S25EditBlackoutComponent } from "./s25.edit.blackout.component";
import { S25TimepickerModule } from "../../s25-timepicker/s25.timepicker.module";
import { S25CheckboxModule } from "../../s25-checkbox/s25.checkbox.module";
import { S25DatePatternModule } from "../../s25-datepattern/s25.datepattern.module";
import { S25MultiselectModule } from "../../s25-multiselect/s25-multiselect.module";
import { FormsModule } from "@angular/forms";
import { S25DurationModule } from "../../s25-duration/s25.duration.module";

@NgModule({
    imports: [
        S25TableModule,
        S25ModalModule,
        CommonModule,
        S25DatepickerModule,
        S25EditableModule,
        S25TimepickerModule,
        S25CheckboxModule,
        S25DatePatternModule,
        S25MultiselectModule,
        FormsModule,
        S25DurationModule,
    ],
    exports: [S25BlackoutManagementListComponent, S25EditBlackoutComponent],
    declarations: [S25BlackoutManagementListComponent, S25EditBlackoutComponent],
    providers: [S25BlackoutManagementListComponent, S25EditBlackoutComponent],
})
export class S25BlackoutManagementModule {}
